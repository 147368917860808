import { trendFetch } from 'api';
import { MIXPANEL_EVENT } from 'constants/mixpanel';
import { trackGTagEvent, GTagEvent } from 'services/analytics/gtag';
import { trackEvent } from 'services/analytics/mixpanel';
import Bugsnag, { addErrorMetaData } from 'services/bugsnag';
import {
  API_RESPONSE_STATUS,
  APIHandlerResponse,
  BrandAuthResponse,
  BrandOAuthResponse,
} from 'types/auth';
import { getCookie, standardizeErrorMessage } from 'utils/auth';
import { calculateDateDifference } from 'utils/dateUtils';

export interface AuthHandlerResponse extends APIHandlerResponse {
  brandAuthResponse?: BrandAuthResponse | BrandOAuthResponse;
}
// eslint-disable-next-line valid-jsdoc
/** For Stape analytics UTM */
const getUrl = () => {
  let url = window.location.href;
  if (!url.toLowerCase().includes('gclid')) {
    const fpgClawCookie = getCookie('FPGCLAW');
    if (fpgClawCookie) {
      const gclid = fpgClawCookie.split('.')[2];
      const newUrl = new URL(url);
      newUrl.searchParams.append('gclid', gclid);
      url = newUrl.toString();
    }
  }
  return url;
};

export const postAuthSignup = async (
  email: string,
  password: string
): Promise<AuthHandlerResponse> => {
  const url = getUrl();
  return await trendFetch('/auth/brand/signup', {
    method: 'POST',
    body: JSON.stringify({ email, password, url }),
  })
    .then(async (res) => {
      const jsonResponse = await res.json();
      const { idToken } = jsonResponse;
      if (res.ok && idToken) {
        trackEvent(MIXPANEL_EVENT.BRAND_SIGNUP, { email, type: 'email' });
        return {
          status: API_RESPONSE_STATUS.OK,
          message: 'success',
          brandAuthResponse: jsonResponse,
        };
      } else {
        if (res.status === 409) {
          return {
            status: API_RESPONSE_STATUS.ERROR,
            message: 'Email already registered, please login',
          };
        }
        const { message } = jsonResponse;
        const errorMessage = standardizeErrorMessage(
          message || 'Failed to sign up'
        );
        return { status: API_RESPONSE_STATUS.ERROR, message: errorMessage };
      }
    })
    .catch((err) => {
      Bugsnag.notify(
        'postAuthSignup Error',
        addErrorMetaData('error', { error: err })
      );
      return { status: API_RESPONSE_STATUS.ERROR, message: 'Failed To Signup' };
    });
};

export const postLoginUser = (
  email: string,
  password: string
): Promise<AuthHandlerResponse> => {
  return trendFetch('/auth/brand/login', {
    method: 'POST',
    body: JSON.stringify({ email, password }),
  })
    .then(async (res) => {
      const jsonResponse = await res.json();
      const { idToken } = jsonResponse;
      if (res.ok && idToken) {
        trackEvent(MIXPANEL_EVENT.BRAND_LOGIN);
        return {
          status: API_RESPONSE_STATUS.OK,
          message: 'success',
          brandAuthResponse: jsonResponse,
        };
      } else {
        const { message } = jsonResponse;
        const errorMessage = standardizeErrorMessage(
          message || 'Failed to login'
        );
        return { status: API_RESPONSE_STATUS.ERROR, message: errorMessage };
      }
    })
    .catch((err) => {
      Bugsnag.notify(
        'postLoginUser Error',
        addErrorMetaData('error', { error: err })
      );
      return { status: API_RESPONSE_STATUS.ERROR, message: 'Failed to login' };
    });
};

export const postGoogleLogin = (): Promise<AuthHandlerResponse> => {
  const url = getUrl();
  return trendFetch('/auth/brand/login/google', {
    method: 'POST',
    body: JSON.stringify({ url }),
  })
    .then(async (res) => {
      const jsonResponse = await res.json();
      if (res.ok) {
        // MP Tracking
        const brandAuthResponse = jsonResponse as BrandOAuthResponse;
        // collect current Date and Created Account Time
        const currentDate: number = Number(new Date());
        const startDate: number = Number(
          new Date(brandAuthResponse.brand.createdAt * 1000)
        );

        // Send event to Google Analytics only if account is created within the last 5 minutes
        const isNewUser =
          calculateDateDifference(startDate, currentDate, 'min') < 5;
        if (isNewUser) {
          const { email } = brandAuthResponse;
          trackGTagEvent({
            event: GTagEvent.COMPLETE_REGISTRATION,
            email_address: email,
          });
          trackEvent(MIXPANEL_EVENT.BRAND_SIGNUP, { type: 'google', email });
        }
        trackEvent(MIXPANEL_EVENT.BRAND_GOOGLE_LOGIN, { isNewUser });
        return {
          status: API_RESPONSE_STATUS.OK,
          message: 'success',
          brandAuthResponse,
        };
      } else {
        const { message } = jsonResponse;
        const errorMessage = standardizeErrorMessage(
          message || 'Failed to verify google authentication'
        );
        return { status: API_RESPONSE_STATUS.ERROR, message: errorMessage };
      }
    })
    .catch((err) => {
      Bugsnag.notify(
        'postGoogleLogin Error',
        addErrorMetaData('error', { error: err })
      );
      return { status: API_RESPONSE_STATUS.ERROR, message: 'Failed to login' };
    });
};

export const getProfile = () => {
  return trendFetch('/auth/brand/profile', {
    method: 'GET',
  })
    .then(async (res) => {
      const jsonResponse = await res.json();
      if (res.ok) {
        return {
          status: API_RESPONSE_STATUS.OK,
          message: 'success',
          brandAuthResponse: jsonResponse,
        };
      } else {
        const { message } = jsonResponse;
        const errorMessage = standardizeErrorMessage(
          message || 'Failed to verify refresh profile'
        );
        return { status: API_RESPONSE_STATUS.ERROR, message: errorMessage };
      }
    })
    .catch((err) => {
      Bugsnag.notify(
        'postGoogleLogin Error',
        addErrorMetaData('error', { error: err })
      );
      return { status: API_RESPONSE_STATUS.ERROR, message: 'Failed to login' };
    });
};

export const postLogout = () => {
  trackEvent(MIXPANEL_EVENT.BRAND_LOGOUT);
  return trendFetch('/auth/brand/logout', {
    method: 'POST',
  });
};
