import { Brand } from './brand';

export enum API_RESPONSE_STATUS {
  OK = 'ok',
  ERROR = 'error',
}

export type APIHandlerResponse = {
  status: API_RESPONSE_STATUS;
  message: string;
};

export interface FirebaseAuthSignUpResponse {
  idToken: string;
  email: string;
  refreshToken: string;
  expiresIn: string; // Seconds until idToken expires
  localId: string; // Firebase UID
}
export interface BrandAuthResponse extends FirebaseAuthSignUpResponse {
  brand: Brand;
  customToken: string;
}

export type BrandOAuthResponse = Omit<
  BrandAuthResponse,
  'idToken' | 'refreshToken' | 'expiresIn'
>;

export interface AuthLoginRequest {
  email: string;
  password: string;
}

export enum AUTH_STATUS {
  AUTHENTICATED = 'authenticated',
  UNAUTHENTICATED = 'unauthenticated',
  LOADING = 'loading',
  ERROR = 'error',
}

export type AuthState =
  | {
      status: AUTH_STATUS.UNAUTHENTICATED | AUTH_STATUS.LOADING;
      error?: string;
      email?: string;
      uid?: string;
      brand?: Brand;
    }
  | {
      status: AUTH_STATUS.ERROR;
      error: string;
      email?: string;
      uid?: string;
      brand?: Brand;
    }
  | {
      status: AUTH_STATUS.AUTHENTICATED;
      error?: undefined;
      email: string;
      uid: string;
      brand: Brand;
    };
