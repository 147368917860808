import { ContentExampleProps } from 'components/views/Auth/ContentExamples';

export enum AUTH_TOKEN {
  ACCESS = 'access_token',
  REFRESH = 'refresh_token',
  CUSTOM = 'trend_custom_auth',
}

export const TOKEN_EXPIRATIONS: Record<AUTH_TOKEN, number> = {
  [AUTH_TOKEN.ACCESS]: 60 * 5, // 5 Minutes
  [AUTH_TOKEN.REFRESH]: 60 * 60 * 24 * 7, // 1 Week
  [AUTH_TOKEN.CUSTOM]: 60 * 5,
};

// UI Assets
export const EXAMPLE_ASSETS: Array<ContentExampleProps> = [
  {
    imgSrc: '/images/example-content/olipop.jpeg',
    contentType: 'Product Photography',
    company: 'Olipop',
  },
  {
    imgSrc: '/images/example-content/no-surrender-gear.jpeg',
    contentType: 'Lifestyle Photography',
    company: 'No Surrender Gear',
  },
  {
    imgSrc: '/images/example-content/kara-md.jpeg',
    contentType: 'Product Photography',
    company: 'KaraMD',
  },
  {
    imgSrc: '/images/example-content/kraywoods.jpeg',
    contentType: 'Portrait Photography',
    company: 'Kraywoods',
  },
  {
    imgSrc: '/images/example-content/vigorem.jpeg',
    contentType: 'Product Photography',
    company: 'Vigorem',
  },
];
